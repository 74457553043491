<template>
  <v-card>
    <v-card-title>
      <v-card-title>Relatório de Entregas - {{ vendorName }}</v-card-title>
      <v-spacer />
      <v-card class="d-flex align-self-center">
      </v-card>

      <v-spacer />
      <v-btn
        class="mr-4"
        color="warning"
        icon
        @click="exportCsv()"
      >
        <v-icon size="30">
          {{ icons.mdiDownload }}
        </v-icon>
      </v-btn>
      <v-icon
        ml-5
        @click="$emit('close')"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="itemsTable"
            :loading="loadingTable"
            loading-text="Carregando dados..."
            disable-sort
          >
            <template #[`item.bonus_value`]="{item}">
              <span :class="{ 'zeroed': Number(item.bonus_value) < 0 }">
                {{ Number(item.bonus_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
              </span>
            </template>

            <template #[`item.invoice_number`]="{item}">
              <span :class="{ 'zeroed': Number(item.bonus_value) < 0 }">
                {{ (item.invoice_number) }}
              </span>
            </template>

            <template #[`item.vehicle_model`]="{item}">
              <span :class="{ 'zeroed': Number(item.bonus_value) < 0 }">
                {{ (item.vehicle_model) }}
              </span>
            </template>

            <template #[`item.client_name`]="{item}">
              <span :class="{ 'zeroed': Number(item.bonus_value) < 0 }">
                {{ (item.client_name) }}
              </span>
            </template>

            <template #[`item.contract_number`]="{item}">
              <span :class="{ 'zeroed': Number(item.bonus_value) < 0 }">
                {{ (item.contract_number) }}
              </span>
            </template>

            <!--  eslint-disable-next-line vue/no-unused-vars -->
            <template v-slot:foot="{items}">
              <tfoot class="fixed-footer">
                <tr>
                  <th>
                    TOTAL: {{ footerDeliveries }}
                  </th>
                  <th>
                  </th>
                  <th>
                  </th>
                  <th>
                  </th>
                  <th class="footer-total">
                    {{ Number(footerTotal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
                  </th>
                </tr>
              </tfoot>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiCalendarBlankOutline, mdiClose, mdiDownload } from '@mdi/js'
import { saveAs } from 'file-saver'

const ExcelJS = require('exceljs')

export default {
  props: {
    vendorName: {
      type: String,
      required: true,
    },
    invoiceDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingTable: false,
      footerDeliveries: [],
      footerTotal: [],
      total: {
        total_delivery: 0,
        total_bonus: 0,
      },
      classNoDataText: '',
      noDataText: '',
      loading: false,
      icons: {
        mdiClose,
        mdiCalendarBlankOutline,
        mdiDownload,
      },
      itemsTable: [],
      headers: [
        { text: 'CONTRATO', value: 'contract_number' },
        { text: 'CLIENTE', value: 'client_name' },
        { text: 'MODELO', value: 'vehicle_model' },
        { text: 'NOTA FISCAL', value: 'invoice_number' },
        { text: 'BONIFICAÇÃO', value: 'bonus_value', align: 'center' },
      ],
    }
  },

  created() {
    this.noDataText = 'BUSCANDO DADOS...'
    this.classNoDataText = 'colorWarning'
    this.getItemsDetails()
  },

  methods: {
    async getItemsDetails() {
      this.loadingTable = true
      const body = {
        invoice_date: this.invoiceDate,
        vendor_name: this.vendorName,
      }
      await axiosIns.post('/api/v1/integration/delivery/filter_vendor', body)
        .then(res => {
          // eslint-disable-next-line prefer-destructuring
          this.itemsTable = res.data.data[0][0]
          this.footerTotal = res.data.data[0].total_bonus
          this.footerDeliveries = res.data.data[0].total_delivery
          this.loadingTable = false
        })
    },

    async exportCsv() {
      const file = await this.getCsv()
      if (file) {
        saveAs(file)
      }
    },

    async getCsv() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')

      worksheet.columns = [
        { header: 'CONTRATO', key: 'contract_number' },
        { header: 'CLIENTE', key: 'client_name' },
        { header: 'MODELO', key: 'vehicle_model' },
        { header: 'NOTA FISCAL', key: 'invoice_number' },
        { header: 'BONIFICAÇÃO', key: 'bonus_value' },
      ]

      this.itemsTable.map(item => {
        worksheet.addRow([
          item.contract_number,
          item.client_name,
          item.vehicle_model,
          item.invoice_number,
          Number(item.bonus_value),
        ])
      })

      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], 'dados_entrega_detalhado', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })

      this.loaderExport = false

      return blob
    },
  },

}
</script>
<style>
.colorWarning {
  color: #ff9800 !important;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorEmpty {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.progress-circular {
  width: 200%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fixed-footer {
  position: sticky;
  bottom: 0;
}
.footer{
  height: 50px;
}
.badge{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 40px;
}
.contracts{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  height: 50px;
  width: 100%;
}

.zeroed{
  color: #C34551;
  font-weight: 700;
}

.footer-total{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
