import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c(VCardTitle,[_vm._v("Relatório de Entregas - "+_vm._s(_vm.vendorName))]),_c(VSpacer),_c(VCard,{staticClass:"d-flex align-self-center"}),_c(VSpacer),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"warning","icon":""},on:{"click":function($event){return _vm.exportCsv()}}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])],1),_c(VIcon,{attrs:{"ml-5":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.loadingTable,"loading-text":"Carregando dados...","disable-sort":""},scopedSlots:_vm._u([{key:"item.bonus_value",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'zeroed': Number(item.bonus_value) < 0 }},[_vm._v(" "+_vm._s(Number(item.bonus_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))+" ")])]}},{key:"item.invoice_number",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'zeroed': Number(item.bonus_value) < 0 }},[_vm._v(" "+_vm._s((item.invoice_number))+" ")])]}},{key:"item.vehicle_model",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'zeroed': Number(item.bonus_value) < 0 }},[_vm._v(" "+_vm._s((item.vehicle_model))+" ")])]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'zeroed': Number(item.bonus_value) < 0 }},[_vm._v(" "+_vm._s((item.client_name))+" ")])]}},{key:"item.contract_number",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'zeroed': Number(item.bonus_value) < 0 }},[_vm._v(" "+_vm._s((item.contract_number))+" ")])]}},{key:"foot",fn:function(ref){
var items = ref.items;
return [_c('tfoot',{staticClass:"fixed-footer"},[_c('tr',[_c('th',[_vm._v(" TOTAL: "+_vm._s(_vm.footerDeliveries)+" ")]),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"footer-total"},[_vm._v(" "+_vm._s(Number(_vm.footerTotal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))+" ")])])])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }