import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,{staticClass:"d-flex"},[_c(VRow,{staticClass:"mt-1"},[_c(VAutocomplete,{staticClass:"mr-2",attrs:{"md":"2","dense":"","outlined":"","items":_vm.listRegionsItems,"label":"Filial"},model:{value:(_vm.itemSearch),callback:function ($$v) {_vm.itemSearch=$$v},expression:"itemSearch"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Mês referência","hide-details":"","readonly":""},model:{value:(_vm.computeItemSearchDate),callback:function ($$v) {_vm.computeItemSearchDate=$$v},expression:"computeItemSearchDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c(VDatePicker,{staticClass:"datePicker p-0",attrs:{"type":"month","width":"660px","locale":"pt-BR"},model:{value:(_vm.itemSearchDate),callback:function ($$v) {_vm.itemSearchDate=$$v},expression:"itemSearchDate"}})],1),_c(VBtn,{staticClass:"ml-4",attrs:{"variant":"tonal","color":"info"},on:{"click":_vm.getDataSalesInputSelected}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSendCircleOutline)+" ")])],1),_c(VBtn,{staticClass:"ml-4",attrs:{"variant":"tonal","color":"error"},on:{"click":_vm.clearFilter}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCircleOffOutline)+" ")])],1),_c(VSpacer),(_vm.loaderExport)?_c(VBtn,{staticClass:"ml-2",attrs:{"color":"warning","outlined":""}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":25,"color":"gray"}})],1):_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"warning","outlined":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])],1)]}}])},[_c(VList,_vm._l((_vm.itemsListExport),function(item){return _c(VListItem,{key:item,attrs:{"link":""}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item)},on:{"click":function($event){return _vm.exportCsv(item)}}})],1)}),1)],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.loadingTable,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"item.deliveries_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deliveries_value ? Number(item.deliveries_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.reference === _vm.loadingCircle ? false : true)?_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":function($event){return _vm.detailsItems(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")]):_vm._e(),(item.reference === _vm.loadingCircle ? true : false)?_c(VProgressCircular,{attrs:{"indeterminate":"","size":25,"color":"gray"}}):_vm._e()]}},{key:"no-data",fn:function(){return [_c('span',{class:_vm.classNoDataText},[_vm._v(_vm._s(_vm.noDataText))])]},proxy:true}],null,true)}),_c(VDialog,{attrs:{"max-width":"1200px","persistent":""},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('details-delivery',{key:_vm.vendorNameToDetails,attrs:{"vendor-name":_vm.vendorNameToDetails,"invoice-date":_vm.invoiceDateToDetails},on:{"close":function($event){_vm.openModal = false}}})],1),_c(VSnackbar,{attrs:{"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c(VBtn,_vm._b({attrs:{"color":"#fff","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" X ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" POR FAVOR, selecione um filtro para realizar a exportação! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }