<template>
  <v-card>
    <v-card-text class="d-flex">
      <v-row class="mt-1">
        <v-autocomplete
          v-model="itemSearch"
          class="mr-2"
          md="2"
          dense
          outlined
          :items="listRegionsItems"
          label="Filial"
        />

        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computeItemSearchDate"
              dense
              outlined
              label="Mês referência"
              hide-details
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="itemSearchDate"
            type="month"
            class="datePicker p-0"
            width="660px"
            locale="pt-BR"
          />
        </v-menu>

        <v-btn
          variant="tonal"
          color="info"
          class="ml-4"
          @click="getDataSalesInputSelected"
        >
          <v-icon size="30">
            {{ icons.mdiSendCircleOutline }}
          </v-icon>
        </v-btn>

        <v-btn
          variant="tonal"
          color="error"
          class="ml-4"
          @click="clearFilter"
        >
          <v-icon size="30">
            {{ icons.mdiCircleOffOutline }}
          </v-icon>
        </v-btn>

        <v-spacer />

        <v-btn
          v-if="loaderExport"
          class="ml-2"
          color="warning"
          outlined
        >
          <v-progress-circular
            indeterminate
            :size="25"
            color="gray"
          >
          </v-progress-circular>
        </v-btn>

        <v-menu
          v-else
          offset-y
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              class="ml-2"
              color="warning"
              outlined
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="30">
                {{ icons.mdiDownload }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="item in itemsListExport"
              :key="item"
              link
            >
              <v-list-item-title
                @click="exportCsv(item)"
                v-text="item"
              />
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-card-text>

    <v-data-table
      :headers="headers"
      :items="itemsTable"
      :loading="loadingTable"
      loading-text="Carregando dados..."
    >
      <template #[`item.deliveries_value`]="{ item }">
        {{
          item.deliveries_value
            ? Number(item.deliveries_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
            : 'R$ 0,00'
        }}
      </template>

      <template #[`item.action`]="{ item }">
        <v-icon
          v-if="item.reference === loadingCircle ? false : true"
          medium
          class="me-2"
          @click="detailsItems(item)"
        >
          {{ icons.mdiEyeOutline }}
        </v-icon>

        <v-progress-circular
          v-if="item.reference === loadingCircle ? true : false"
          indeterminate
          :size="25"
          color="gray"
        />
      </template>

      <template v-slot:no-data>
        <span :class="classNoDataText">{{ noDataText }}</span>
      </template>
    </v-data-table>

    <v-dialog
      v-model="openModal"
      max-width="1200px"
      persistent
    >
      <details-delivery
        :key="vendorNameToDetails"
        :vendor-name="vendorNameToDetails"
        :invoice-date="invoiceDateToDetails"
        @close="openModal = false"
      />
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      color="error"
    >
      POR FAVOR, selecione um filtro para realizar a exportação!

      <template v-slot:action="{ attrs }">
        <v-btn
          color="#fff"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import {
  mdiCheckDecagram,
  mdiCircleOffOutline,
  mdiDownload,
  mdiEyeOutline,
  mdiMagnify,
  mdiSendCircleOutline,
  mdiTrendingUp,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import DetailsDelivery from './DetailsDelivery.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    DetailsDelivery,
  },

  data() {
    return {
      dateMenu: false,
      snackbar: false,
      openModal: false,
      loadingTable: false,
      loaderExport: false,

      reference: '',
      itemSearch: '',
      loadingCircle: '',
      itemSearchDate: '',

      headers: [
        {
          text: 'NOME',
          value: 'name',
          sortable: false,
          align: '',
        },
        {
          text: 'CPF',
          value: 'cpf',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Nº ENTREGAS',
          value: 'deliveries',
          sortable: false,
          align: 'center',
        },
        {
          text: 'VALOR ENTREGA',
          value: 'deliveries_value',
          sortable: false,
          align: 'center',
        },
        {
          text: 'REFERÊNCIA',
          value: 'reference',
          sortable: false,
          align: 'center',
        },
        {
          text: 'AÇÕES',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      itemsListExport: ['Sintético', 'Analítico'],
      listRegionsItems: ['IMPERATRIZ', 'AÇAILÂNDIA', 'GRAJAÚ'],

      shops: [
        'AÇAILÂNDIA',
        'AMARANTE',
        'ARAME',
        'BOM JESUS',
        'CIDELÂNDIA',
        'FORMOSA',
        'GRAJAÚ',
        'IMPERATRIZ',
        'ITINGA',
        'PORTO FRANCO',
        'SENADOR',
        'SITIO NOVO',
      ],

      noDataText: '',
      classNoDataText: '',
      vendorNameToDetails: '',
      invoiceDateToDetails: '',

      icons: {
        mdiTrendingUp,
        mdiEyeOutline,
        mdiMagnify,
        mdiCheckDecagram,
        mdiSendCircleOutline,
        mdiDownload,
        mdiCircleOffOutline,
      },
    }
  },

  computed: {
    computeItemSearchDate() {
      return this.formatDate(this.itemSearchDate)
    },
  },

  created() {
    this.noDataText = 'SELECIONE UM FILTRO'
    this.classNoDataText = 'colorWarning'
  },

  methods: {
    async getItemsTable() {
      this.loadingTable = true

      await axiosIns.post('/api/v1/integration/delivery/deliveries_region_list').then(res => {
        this.itemsTable = res.data.data
        this.loadingTable = false
      })
    },

    async getDataSalesInputSelected() {
      this.loadingTable = true
      this.itemsTable = []

      const body = {
        region: this.itemSearch,
        invoice_date: this.itemSearchDate,
      }

      await axiosIns.post('/api/v1/integration/delivery/deliveries_region_list', body).then(res => {
        this.itemsTable = res.data.data
        this.loadingTable = false
      })

      this.noDataText = 'NENHUM REGISTRO ENCONTRADO'
      this.classNoDataText = ''
    },

    async exportCsv(item) {
      const file = await this.getCsv(item)
      if (file) {
        saveAs(file)
      }
    },

    async getCsv(itemReport) {
      this.loaderExport = true

      if (this.itemSearch === '' && this.itemSearchDate === '') {
        this.snackbar = true
        this.loaderExport = false

        return null
      }

      if (itemReport === 'Sintético') {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('sheet')

        worksheet.columns = [
          { header: 'NOME', key: 'name' },
          { header: 'CPF', key: 'cpf' },
          { header: 'Nº ENTREGAS', key: 'deliveries' },
          { header: 'VALOR ENTREGA', key: 'deliveries_value' },
          { header: 'REFERÊNCIA', key: 'reference' },
        ]

        this.itemsTable.map(item => {
          worksheet.addRow([item.name, item.cpf, item.deliveries, Number(item.deliveries_value), item.reference])
        })

        let blob = null
        await workbook.xlsx.writeBuffer().then(data => {
          blob = new File([data], 'dados_entregas', {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        })

        this.loaderExport = false

        return blob
      }

      if (itemReport === 'Analítico') {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('sheet')

        const body = {
          region: this.itemSearch,
          invoice_date: this.itemSearchDate,
        }

        await axiosIns.post('/api/v1/integration/delivery/controlling_deliveries', body).then(res => {
          this.itemsTableDetails = res.data.data
        })

        worksheet.columns = [
          { header: 'VENDEDOR', key: 'name' },
          { header: 'CLIENTE', key: 'client_name' },
          { header: 'NOTA FISCAL', key: 'invoice_number' },
          { header: 'MODELO', key: 'vehicle_model' },
          { header: 'CONTRATO', key: 'contract_number' },
          { header: 'BÔNUS', key: 'bonus_value' },
        ]

        this.itemsTableDetails.map(item => {
          worksheet.addRow([
            item.name,
            item.client_name,
            item.invoice_number,
            item.vehicle_model,
            item.contract_number,
            Number(item.bonus_value),
          ])
        })

        let blob = null
        await workbook.xlsx.writeBuffer().then(data => {
          blob = new File([data], 'dados_entregas', {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        })

        this.loaderExport = false

        return blob
      }

      return ''
    },

    detailsItems(item) {
      this.openModal = true
      this.vendorNameToDetails = item.name
      this.invoiceDateToDetails = item.invoice_date
    },

    async clearFilter() {
      this.itemSearch = ''
      this.itemSearchDate = ''

      this.itemsTable = []
    },

    /**
     * Brazilian standard date format
     *
     * @param {string} date
     * @returns formatted date
     */
    formatDate(date) {
      if (!date) return null

      const [year, month] = date.split('-')

      return `${month}/${year}`
    },
  },
}
</script>

<style>
.colorWarning {
  color: #ff9800 !important;
}

.datePicker {
  width: 100%;
}
</style>
